import React from 'react';
import CircleComponent from '../components/CircleComponent';
import './MapaSgr.css';
import { ArcherContainer, ArcherElement } from 'react-archer';
import WebErp from '../image/AvalOC.png';
import Dynamics365 from '../image/Dynamics365.png';
import PortalCliente from '../image/PortalCliente.png';
import OnboardingDigital from '../image/Onboarding.png';
import AvalOneClick from '../image/AVALOCPC.png';
import SocKey from '../image/PowerBI.png';
import Web from '../image/WEb.png';
import SocioProtector from '../image/SocioProtecto.png'
import SocioParticipe from '../image/SocioParticipe.png'
import SGR from '../image/SGROC.png'


const MapaSgr = () => {
  return (
    <ArcherContainer strokeColor="#7A7C7F" strokeWidth={1}>
      <div className="Mapa">
        <div className='title'>
          <h4>Mapa de soluciones</h4>
          {/* <img
            src={portalOC}
            alt="Logo Aval OC"
            className='logoportalOC'
          /> */}
        </div>
        <div className="lineSgr">
          <ArcherElement id="sitio-web"
           relations={[
            {
              targetId: 'portal-socio-protector', 
              targetAnchor: 'left',
              sourceAnchor: 'bottom',
            },
            {
              targetId: 'ms-dynamics-crm',
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
            {
              targetId: 'portal-socio', //portal del socio participe
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
            {
              targetId: 'onboarding-digital',
              targetAnchor: 'top',
              sourceAnchor: 'bottom',
            },
            
          ]}
          >
            <div>
              <CircleComponent
                name="Sitio WEB"
                imageUrl={Web}
                esRect
              />
            </div>
          </ArcherElement>
        </div>
        <div className="line-protector">
          <ArcherElement
            id="portal-socio-protector"
            relations={[
              {
                targetId: 'sgr-one-click',
                targetAnchor: 'top',
                sourceAnchor: 'bottom',
              },
              
            ]}
          ><div>
              <CircleComponent
                name="Portal del Socio Protector"
                imageUrl={SocioProtector}
                esRect
              />
            </div>
          </ArcherElement>
        </div>
        <div className="line-dynamics-sgr">
          <ArcherElement
            id="ms-dynamics-crm"
            relations={[
              {
                targetId: 'sgr-one-click', //SGR ONE CLICK
                targetAnchor: 'top',
                sourceAnchor: 'bottom',
              },
            ]}
          ><div>
              <CircleComponent
                name="MS Dynamics CRM"
                imageUrl={Dynamics365}
                esRect
              />
            </div>
          </ArcherElement>
        </div>
        <div className="lineSgr">
          <div className="circle-container bottom">
            <ArcherElement
              id="portal-socio" //socio parti
              relations={[
                {
                  targetId: 'sgr-one-click', //sgr one click
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                },
                
              ]}
            ><div>
              <CircleComponent
                name="Portal del Socio Participe"
                imageUrl={SocioParticipe}
                esRect
              /></div>
            </ArcherElement>
            <ArcherElement
              id="onboarding-digital"
              relations={[
                {
                  targetId: 'sgr-one-click', //SGR
                  targetAnchor: 'middle',
                  sourceAnchor: 'bottom',
                  style: { strokeWidth: 1, strokeColor: '#7A7C7F' }
                }, 
               
              ]}
            ><div>
              <CircleComponent
                name="Onboarding Digital"
                imageUrl={OnboardingDigital}
                esRect
              /></div>
            </ArcherElement>
          </div>
        </div>
        <div className="lineSgr">
          <ArcherElement id="sgr-one-click">
            <div>
            <CircleComponent
              name="SGR One Click" //SGR ONE CLICK
              imageUrl={SGR}
              esRect
            /></div>
          </ArcherElement>
        </div>
        
        <div className="lineSgr">
          <ArcherElement id="soc-key"
            relations={[
              {
                targetId: 'portal-socio', //SGR
                targetAnchor: 'bottom',
                sourceAnchor: 'left',
              },
              {
                targetId: 'portal-socio-protector', //SGR
                targetAnchor: 'bottom',
                sourceAnchor: 'right',
                style: { strokeWidth: 1, strokeColor: '#7A7C7F' }
              },
            ]}
          >
            <div>
              <CircleComponent
                name="SOC Key"
                imageUrl={SocKey}
                esRect
              />
            </div>
          </ArcherElement>
        </div>
      </div>
    </ArcherContainer>
  );
};

export default MapaSgr;
