import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import SobreNosotros from "./pages/Quienes Somos/SobreNosotros";
import { RouterLayout } from "./components/RouterLayout";
import Contacto from "./pages/contacto/Contacto";
import PoliticaPrivacidad from "./pages/politica de privacidad/PoliticaPrivacidad";
import FormularioContacto from "./pages/contacto/FormularioContacto";
import AvalOneClick from "./pages/avalOneClick/AvalOneClick";
import SgrOneClick from "./pages/SGROneClick/SgrOneClick";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<RouterLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/aval-one-click" element={<AvalOneClick />} />
        <Route path="/sgr-one-click" element={<SgrOneClick />} />
        <Route path="/quienes-somos" element={<SobreNosotros />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/politica-de-privacidad" element={<PoliticaPrivacidad />} />
      </Route>
      <Route path="/formulario-contacto" element={<FormularioContacto />} />
    </Routes>
  );
};
