import {
  Accordion,
  AccordionDetails,
  Container,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../../Firebase";
import styles from "./SobreNosotros.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import imgPartner from "../../image/partners.jpg";
import Brochure from "../../docs/Brochure-Humanware.pdf";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import imageParallax from "../../image/image-parallax4.jpg";
import fintechSGR from "../../image/fin-tech.png";
import trengSGR from "../../image/trends.png";
import fidus from "../../image/fidus.png";
import innovaSGR from "../../image/innova.png";
import confiablesSGR from "../../image/confiables.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import img from "../../image/page-sobreNosotros.png";

function SobreNosotros() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location && location.pathname) {
      try {
        analytics.logEvent('screen_view', {
          screen_name: location.pathname,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [location]);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Helmet>
        <title>Quienes Somos - One Click Applications</title>
        <meta
          name="description"
          content="One Click Applications es una firma profesional, Partner de Microsoft, 
          que provee soluciones corporativas para la transformación digital de los Procesos."
        />
        <meta name="keywords" content="One Click Applications, SGR, Microsoft, Partner de Microsoft"></meta>
      </Helmet>
      <Container
        sx={{
          mt: 0,
          background: `rgba(0, 0, 0, 0.7) url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minWidth: "100vw",
          minHeight: "75vh",
          backgroundBlendMode: "darken",
        }}
      >
        <Typography variant="h6" color="primary" sx={{ pt: 35, pl: 15 }} className={`${styles.parrafo01}`}>
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon color="primary" className={`${styles.link}`} />
          </Link>
          Sobre Nosotros
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Soluciones Corporativas para la Transformación digital de los Procesos.
        </Typography>
      </Container>
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          marginTop: { xs: 0, md: 10 },
        }}
      >
        <Grid item xs={16} md={7} sx={{ px: { xs: 0, md: 10 }, pb: { xs: "1rem" } }}>
          <Typography
            variant="subtitle2"
            color="#7A7C7F"
            sx={{
              px: { xs: 1, md: 5 },
              fontWeight: 500,
              color: "#248da5",
              textAlign: "center",
              fontSize: { xs: "1.25rem", md: "1.5rem" },
            }}
          >
            Sobre Nosotros - Quienes somos
          </Typography>
          <Typography
            variant="h4"
            color="#414042"
            sx={{ fontWeight: 700, p: { xs: 1, md: 5 }, textAlign: "center", fontSize: { xs: "1rem", md: "1.25rem" } }}
          >
            Somos Socios de Microsoft
          </Typography>
          <Typography
            variant="subtitle1"
            color="#7A7C7F"
            sx={{ px: { xs: 2, md: 5 }, mt: { xs: "1rem", md: "1rem" }, mb: { xs: "2rem", md: "1rem" } }}
          >
            One Click Applications es una firma profesional, Partner de Microsoft, que provee soluciones corporativas
            para la transformación digital de los Procesos. Creemos que la gestión de competencias del capital humano,
            combinado con la estrategia de implementación de modernas visiones, metodologías y soluciones tecnológicas,
            son la base de la Innovación, Diferenciación y Competitividad Organizacional.
          </Typography>
          {/* <Box
            component="a"
            href={Brochure}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "#fff",
              marginLeft: { xs: "1rem", md: "2.2rem" },
              padding: "1rem 1.5rem",
              backgroundColor: "#6a9818",
              textDecoration: "none",
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#394e19",
                color: "#fff",
              },
            }}
          >
            Ver Brochure
          </Box> */}
        </Grid>
        <Grid item xs={8} className={`${styles.imgPartnerContainer}`}>
          <img className={`animate__animated animate__fadeInUp`} src={imgPartner} alt="Partner" />
        </Grid>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" sx={{ color: "#1786A0", fontWeight: "bold", mt: { xs: 5, md: 10 } }}>
            People Before Strategy
          </Typography>
          <Typography
            variant="h4"
            color="initial"
            align="center"
            sx={{
              fontWeight: "bold",
              p: { xs: 4, md: 8 },
              color: "#414042",
              fontSize: { xs: "1.125rem", md: "1.25rem" },
            }}
          >
            Proveemos soluciones corporativas para la
            <br />
            transformación digital de los Procesos.
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            sx={{
              textAlign: { xs: "start", md: "center" },
              color: "#7A7C7F",
              fontWeight: "500",
              px: 4,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            En One Click Applications creemos que la Competitividad Organizacional
            <br />
            se apoya en la transformación digital con soluciones y plataformas
            <br />
            de desarrollo rápido.
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            sx={{
              textAlign: { xs: "start", md: "center" },
              color: "#7A7C7F",
              fontWeight: "500",
              mt: { xs: 1, md: 2 },
              px: 4,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            Somos una empresa con experiencia y proyección, integrada por profesionales
            <br />
            de diferentes disciplinas para generar el desarrollo y la innovación
            <br />
            constante de nuestros productos.
          </Typography>
          <hr sx={{ color: "#1786A0" }} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ mb: {xs:1, md:20}, mt: 10, px: 2, mx: 4, gap: 2 }}
        >
          <Card
            sx={{
              maxWidth: 375,
              maxHeight: 405,
              backgroundColor: "#fff",
              "&:hover": {
                borderTop: "2px solid #517417",
                borderLeft: "2px solid #517417",
              },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <LibraryBooksIcon fontSize="large" sx={{ color: "#394e19" }} />
              </Typography>
              <Typography variant="h6" component="div" color="secondary" sx={{ textAlign: "center" }}>
                Aplicaciones
              </Typography>
              <Typography sx={{ px: 5, pt: 3, pb: 1, textAlign: "center" }} color="secondary" align="center">
                Aplicaciones Core para el negocio, como SGR One Click gestión Integral de SGRs, HR One Click gestión de
                Recursos Humanos, QUA One Click control de calidad, CM One Click gestion construcción, Portal One Clcik
                portales webs.
              </Typography>
              {/* <CardActions>
                <Link to="/aplicaciones" size="medium" className={`${styles.link}`}>
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Link>
              </CardActions> */}
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 375,
              minHeight: 415,
              backgroundColor: "#fff",
              "&:hover": {
                borderTop: "2px solid #517417",
                borderLeft: "2px solid #517417",
              },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <QuestionAnswerIcon fontSize="large" sx={{ color: "#394e19" }} />
              </Typography>
              <Typography variant="h6" component="div" color="secondary" sx={{ textAlign: "center" }}>
                Corporate Performance Management
              </Typography>
              <Typography sx={{ px: 5, pt: 3, pb: 1, textAlign: "center" }} color="secondary" align="center">
                Corporate Performance Management con Kepion Planning y Power BI como soluciones para gestión estratégica
                y táctica del negocio.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              maxWidth: 375,
              maxHeight: 405,
              backgroundColor: "#fff",
              "&:hover": {
                borderTop: "2px solid #517417",
                borderLeft: "2px solid #517417",
              },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
                <LibraryAddCheckIcon fontSize="large" sx={{ color: "#394e19" }} />
              </Typography>
              <Typography variant="h6" component="div" color="secondary" sx={{ textAlign: "center" }}>
                Capacitaciones
              </Typography>
              <Typography
                className={`${styles.parrafoCard02}`}
                sx={{ mb: 1.5, px: 5, py: 3 }}
                color="secondary"
                align="center"
              >
                Capacitación y desarrollo de competencias internas para la efectiva transferencia de know how en la
                tecnología y nuestras soluciones.
              </Typography>
              {/* <CardActions>
                <Link to="/capacitaciones" size="medium" className={`${styles.link}`}>
                  <Typography
                    className={`${styles.buttonHoverCard}`}
                    variant="subtitle2"
                    color="#757575"
                    sx={{ fontWeight: 700 }}
                  >
                    Ver Más <KeyboardArrowRightIcon />
                  </Typography>
                </Link>
              </CardActions> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid container direction="column" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h4"
            color="initial"
            align="center"
            sx={{ fontWeight: "bold", p: {xs:4, md:8}, color: "#414042", fontSize: { xs: "1.25rem", md: "1.5rem" }  }}
          >
            Soluciones de negocio y transferencia de know how
            <br />
            transformación digital.
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            sx={{ color: "#7A7C7F", fontWeight: "fontWeightMedium", fontSize: {xs:"1rem" ,md: "1.25rem"}, mx:{xs: 4, md:1}, textAlign: {xs: "start", md:"center"} }}
          >
            Modernas visiones y metodologías de trabajo que ayudan a las compañías a ser
            <Box component="br" sx={{display: {xs: "none", md:"inline-block"}}} />
            más competitivas y a diferenciarse dentro del mercado.
          </Typography>
          <hr sx={{ color: "#1786A0" }} />
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{
            mt: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: {xs: "center",md:"start"},
          }}
        >
          <Grid  item xs={12} md={7}>
            <Box component="img" src={imageParallax} sx={{width: {xs: "100%", md:"90%"} }} alt="imagen" />
          </Grid>
          <Grid item xs={12} md={7} sx={{maxWidth: "450px", mt:{xs: 2, md:1}}}>
            <Accordion
              className={`${styles.acordionItem}`}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                  One Click Training Center
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="#414042">
                  One Click Applications cuenta con un centro equipado para brindar capacitaciones en aula y/o talleres
                  de trabajo en tecnologías Microsoft para ayudar a las compañías a lograr su transformación digital.
                  Nuestro principal objetivo es transferir conocimientos en tecnologías, modernas visiones y
                  metodologías de trabajo que ayudan a las compañías a ser más competitivas y a diferenciarse dentro del
                  mercado.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={`${styles.acordionItem}`}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ backgroundColor: "#EDF9ED", mb: 3 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#414042" }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography color="#414042" sx={{ py: 2, fontWeight: 600 }}>
                  Software Factory
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="#414042">
                  En la ciudad de Montevideo, Uruguay, One Click Applications ha montado su Software Factory,
                  especializada en Soluciones Tecnológicas Microsoft, desde donde provee soporte y servicios de valor
                  agregado a nuestros clientes y socios de negocios sobre nuestras Aplicaciones. Con un equipo altamente
                  capacitado y certificado, OC Software Factory personaliza las soluciones de acuerdo a los proyectos,
                  objetivos y necesidades de cada cliente.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid
          className={`${styles.containerPrefooter}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            py: 8,
          }}
          container
          spacing={2}
        >
          <Grid item className={`${styles.imagenesContainer}`}>
            <img src={fintechSGR} className={`${styles.imagenesLogo}`} alt="finanzas y Creditos" />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img src={trengSGR} className={`${styles.imagenesLogo}`} alt="Sancor Seguros" />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img src={fidus} className={`${styles.imagenesLogo}`} alt="Origenes" />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img src={innovaSGR} className={`${styles.imagenesLogo}`} alt="Megatlon" />
          </Grid>
          <Grid item className={`${styles.imagenesContainer}`}>
            <img src={confiablesSGR} className={`${styles.imagenesLogo}`} alt="Bolsa Comercio" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SobreNosotros;
