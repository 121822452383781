import React, { useState, useRef } from "react";
import { Typography, Box, TextField, Button } from "@mui/material";
import emailjs from "@emailjs/browser";
import styles from "./Formulario.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Formulario() {
  const form = useRef();
  const [contactoData, setContactoData] = useState({
    nombre: "",
    celular: "",
    email: "",
    mensaje:""
  });

  const { nombre, celular, email, mensaje } = contactoData;
  const dataLogin = (e) => {
    setContactoData({ ...contactoData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_one_click",
        "template_o2bgdzh",
        e.target,
        "NyaTavrWBOn-FLIpi"
      )
      .then(
        (result) => {
          setContactoData({ nombre: "", celular: "", email: "",mensaje:"" });
          toast.success("Su consulta se envio con éxito", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Box
    
      container
      className={`${styles.formContainer}`}
      sx={{
        backgroundColor: "#fff",
        maxWidth: "50vw",
        backgroundColor: "#fff",
        mx: "auto",
        mb:6,
        borderRadius: "15px",
      }}
    >
      <Typography
        variant="h6"
        color="#414042"
        align="center"
        sx={{ py: 4, fontWeight: 600 }}
      >
        ¿Te gustaría descubrir cómo transformar tu empresa?
      </Typography>
      <Typography
        variant="subtitle1"
        color="#414042"
        align="center"
        sx={{ px: 5 }}
      >
        Dejanos tus datos, te mostraremos cómo nuestras soluciones empresariales pueden impulsar el crecimiento de tu negocio de manera eficiente e inteligente.
      </Typography>

      <Box ref={form} component="form" onSubmit={handleSubmit} sx={{ px: 10 }} className={`${styles.boxForm}`}>
        <TextField
          color="secondary"
          name="nombre"
          margin="normal"
          type="text"
          fullWidth
          label="Nombre"
          sx={{ mt: 1.5, mb: 1.5 }}
          required
          value={nombre}
          onChange={dataLogin}
        />
        <TextField
          color="secondary"
          name="celular"
          margin="normal"
          type="number"
          fullWidth
          label="Celular"
          sx={{ mt: 1.5, mb: 1.5 }}
          required
          value={celular}
          onChange={dataLogin}
        />
        <TextField
          color="secondary"
          name="email"
          margin="normal"
          type="email"
          fullWidth
          label="Email"
          sx={{ mt: 1.5, mb: 1.5 }}
          required
          value={email}
          onChange={dataLogin}
        />
        <TextField
          color="secondary"
          name="mensaje"
          margin="normal"
          type="text"
          fullWidth
          label="Mensaje"
          multiline
          minRows={3}
          value={mensaje}
          onChange={dataLogin}
        />
        <Button
          color="secondary"
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 1.5, mb: 3, backgroundColor: "#0186A0" }}
        >
          Enviar formulario
        </Button>
        <Button
          variant="contained"
          fullWidth
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#25D366",
            color: "#fff",
            fontSize: "16px",
            mb: 3,
            padding: "10px 16px",
          }}
          onClick={() => {
            const mensajeWhatsApp = `Hola, quiero agendar una demo. Mis datos son:
            - Nombre: ${nombre}
            - Celular: ${celular}
            - Email: ${email}
            - Mensaje: ${mensaje}`;

            const url = `https://wa.me/5493804219440?text=${encodeURIComponent(mensajeWhatsApp)}`;
            window.open(url, "_blank");
          }}
        >
          <WhatsAppIcon sx={{ marginRight: "8px" }} />
          Agendar DEMO
        </Button>
        
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />   

      
    </Box>
  );
}

export default Formulario;
