
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { analytics } from "../../Firebase";
import { Box, Typography, Container, Button } from '@mui/material'
import styles from "./PoliticaPrivacidad.module.css"
import { Link } from "react-router-dom"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown"

const PoliticaPrivacidad = () => {
  const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
    if (location && location.pathname) {
      try {
        analytics.logEvent('screen_view', {
          screen_name: location.pathname,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [location]);

  return (
    <>
    <Box sx={{ mt: 0 }} className={`${styles.fondoPoliticasSeguridad}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15, mb:10 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
            Política de privacidad para Web de One Click Applications
        </Typography>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: "5rem",
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 450)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Ver políticas<br/>
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
    </Box>
    <Box sx={{ display:"flex", flexDirection:"column", gap:5, justifyContent:"center", alignItems:"center", p:2}}>
        <Typography variant="h1" sx={{ fontSize:{ md:"1.5rem", xs:"1rem"}, fontWeight:600, mt:7, mb:4}}>Política de privacidad para Web de One Click Applications</Typography>
        <Typography variant="body1" sx={{ fontSize:{ md:"1rem", xs:".9rem"}, px:{md:"4rem"}}}>Desde One Click Applications somos respetuosos de tu privacidad y datos 
            personales; cumplimos con todas las pautas legales exigidas y tomamos las medidas de seguridad correspondientes conforme lo 
            establecido en la legislación nacional vigente (Ley Nº 25.326 de Protección de Datos Personales y sus normas complementarias 
            de la República Argentina)
        </Typography>
        <Typography variant="body1" sx={{ fontSize:{ md:"1rem", xs:".9rem"}, px:{md:"4rem"} }}>Desde el presente sitio te podemos pedir que ingreses determinados datos 
            personales, como: nombre, apellido, empresa, domicilio, dirección de mail y teléfono de contacto. Utilizando dichos datos para 
            enviarte información de productos, servicios y promociones de la empresa, obtener estadísticas y/o uso indistinto de marketing.
        </Typography>
        <Typography variant="body1" sx={{ fontSize:{ md:"1rem", xs:".9rem"}, px:{md:"4rem"} }}>En ningún caso proporcionar tus datos personales es condición para el uso de 
            este sitio web y sus servicios vinculados. Todos los datos proporcionados por vos son brindados en forma voluntaria y son tomados 
            como información cierta y veraz, prestando tu consentimiento libre e informado al ingresar los mismos.
        </Typography>
        <Typography variant="body1" sx={{ fontSize:{ md:"1rem", xs:".9rem"}, px:{md:"4rem"} }}>Podrás acceder a tus datos de carácter personal para hacer uso de tu derecho a rectificarlos, 
            cancelarlos u oponerte a su tratamiento, mediante notificación al responsable del tratamiento de datos (One Click Applications) al mail 
             <Box component="a" href="mailto:ventas@oneclick-apps.com" sx={{textDecoration:"none"}}> ventas@oneclick-apps.com</Box>, sin cargo alguno.
        </Typography>
        <Typography variant="body1" sx={{ fontSize:{ md:"1rem", xs:".9rem"}, px:{md:"4rem"}, pb:"5rem" }}>One Click Applications no cederá información sobre tus datos personales a terceros. No obstante, 
            podrá enviarte ofertas promocionales o comunicados especiales, las cuales siempre podrás optar por solicitar dejar de recibirlos enviando mail a la 
            casilla de correo antes mencionada.
        </Typography>
    </Box>
    </>
  )
}

export default PoliticaPrivacidad
