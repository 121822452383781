import { Box, Typography, Grid, Card, CardContent, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../../Firebase";
import { Link } from "react-router-dom";
import styles from "./Contacto.module.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import Formulario from "../../components/Formulario";
import { Helmet } from "react-helmet";
import img from "../../image/contacto.jpg";

function Contacto() {
 const location = useLocation(); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location && location.pathname) {
      try {
        analytics.logEvent('screen_view', {
          screen_name: location.pathname,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [location]);


  return (
    <>
      <Helmet>
        <title>Contacto - One Click Applications</title>
        <meta
          name="description"
          content="En One Click Applications proveemos soluciones corporativas para la
          transformación digital de los Procesos."
        />
        <meta
          name="keywords"
          content="one click, applications, contacto One Click, contacto One Click Applications,"
        ></meta>
      </Helmet>
      <Container
        sx={{
          mt: 0,
          background: `rgba(0, 0, 0, 0.7) url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minWidth: "100vw",
          minHeight: "75vh",
          backgroundBlendMode: "darken",
        }}
      >
        <Typography variant="h6" color="primary" sx={{ pt: 35, pl: 15 }} className={`${styles.parrafo01}`}>
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon color="primary" className={`${styles.link}`} />
          </Link>
          Contacto
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          Acá Empieza el Crecimiento.
        </Typography>
      </Container>
      <Grid
        className={`${styles.contactoContainer}`}
        container
        columns={16}
        sx={{
          my: 1,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Grid item xs={7} className={`${styles.contactoIframe}`}>
          <iframe
            className={`${styles.iframe}`}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.653095617317!2d-56.188636084769774!3d-34.90574398037777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80ffc63bf7d5%3A0x6b17e074eaca8a8c!2sMisiones%201537%2C%2011100%20Montevideo%2C%20Departamento%20de%20Montevideo%2C%20Uruguay!5e0!3m2!1sen!2suy!4v1633099092834"
            width="600"
            height="450"
            style={{ border: "1px solid #9CC0F9" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid item xs={7} className={`${styles.contactoFormulario}`}>
          <Formulario />
        </Grid>
      </Grid>
      <Grid
        className={`${styles.contactoCard}`}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 10, mt: 10 }}
      >
        <Card className={`${styles.cardHover}`} sx={{ minHeight: 300, minWidth: 400, backgroundColor: "#fff" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ p: 4 }} color="secondary" gutterBottom>
              <LocationOnIcon fontSize="large" sx={{ mr: 0.5, color: "#1786A0" }} />
            </Typography>
            <Typography variant="h6" component="div" color="secondary" sx={{ fontWeight: 600 }}>
              Montevideo - Uy
            </Typography>
            <Typography sx={{ mb: 1.5, px: 5, py: 3 }} color="secondary" align="center">
              <LocationOnIcon fontSize="small" sx={{ color: "#1786A0" }} /> Misiones 1537
            </Typography>
            <Typography sx={{ mb: 1.5, px: 5 }} color="secondary" align="center">
              <PhoneIcon fontSize="small" sx={{ color: "#1786A0" }} /> 099252339 - 29155020
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default Contacto;
