
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAf9LPCWQfg0u-ad7y5aocI47x24dj4mMo",
  authDomain: "oneclickapplications-b88d6.firebaseapp.com",
  projectId: "oneclickapplications-b88d6",
  storageBucket: "oneclickapplications-b88d6.appspot.com",
  messagingSenderId: "413818900780",
  appId: "1:413818900780:web:83c6a58c6f4742782c9c89",
  measurementId: "G-K8J0YFKQGK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };