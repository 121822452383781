import React, { useEffect } from "react";
import styles from "./SgrOneClick.module.css";
import { useLocation } from "react-router-dom";
import { analytics } from "../../Firebase";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import fintechSGR from "../../image/fin-tech.png";
import trengSGR from "../../image/trends.png";
import fidus from "../../image/fidus.png";
import innovaSGR from "../../image/innova.png";
import confiablesSGR from "../../image/confiables.png";
import millsSGR from "../../image/mills.png";
import Formulario from "../../components/Formulario";
import SGROC from "../../image/SGROC Blanco.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from "react-helmet";
import brochure from "../../docs/SGR One Click - Brochure.pdf"
import MapaSgr from "../../components/MapaSgr";

function SgrOneClick() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location && location.pathname) {
      try {
        analytics.logEvent('screen_view', {
          screen_name: location.pathname,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [location]);


  return (
    <>
      <Helmet>
        <title>Aplicaciones SGR One Click - Humanware Applications</title>
        <meta
          name="description"
          content="Conoce las aplicaciones que tenemos para tu empresa y comenzá a optimizar tus proceso."
        />
        <meta
          name="keywords"
          content="humanware, humanware applications, aplicaciones humanware, , aplicaciones humanware applications, SGR One Click"
        ></meta>
      </Helmet>
      <Box sx={{ mt: 0 }} className={`${styles.fondoSGROneClick}`}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ pt: 35, pl: 15 }}
          className={`${styles.parrafo01}`}
        >
          <Link to="/" className={`${styles.link}`}>
            Home
            <KeyboardArrowRightIcon
              color="primary"
              className={`${styles.link}`}
            />
          </Link>
          SGR One Click
        </Typography>
        <Typography
          className={`${styles.parrafo02}`}
          variant="h4"
          color="primary"
          sx={{ pt: 2, pl: 15, fontWeight: 600 }}
        >
          <img src={SGROC} className={` ${styles.logoSGR}`} alt="Logo SGR" />
        </Typography>
        <a
          className={`btn ${styles.buttonBrochure}`}
          href={brochure}
          target="_blank"
          rel="noopener noreferrer"
          >
            Ver Brochure
        </a>
        <Container
          className={`${styles.botonDemo}`}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button variant="text" onClick={() => window.scroll(0, 2050)}>
            <Typography
              variant="subtitle1"
              color="primary"
              className={`${styles.botonText}`}
            >
              Solicite una demo <br />
              <KeyboardDoubleArrowDownIcon
                color="primary"
                className={`${styles.botonText}`}
              />
            </Typography>
          </Button>
        </Container>
      </Box>
      {/* <Box className={`${styles.fondoSGROneClick2}`}></Box> */}
      <Box className={`${styles.fondoGestiondeCapital}`}>
        <MapaSgr />
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, minHeight: "80vh" }}
        >
          <Grid
            className={`${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              pb: 2,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="secondary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              SGR: La Necesidad
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="secondary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Las Sociedades de Garantías Recíprocas están teniendo un
              crecimiento significativo de volumen de operaciones y han nacido
              como una unidad de negocio de Grupos Empresarios.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="secondary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              En general sus procesos operacionales, de control de gestión y de
              cumplimiento de requerimientos informativos de la SEPYME con
              numerosos anexos se apoyan en procesos semi manuales con uso
              intensivo de planillas Excel.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="secondary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              Humanware Applications ha desarrollado SGR One Click, una
              aplicación especifica para soportar todos los procesos y
              operaciones para cumplir con las normas y regulaciones legales.
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen1}`}
          ></Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={16}
          sx={{ pt: 0, mb: 10, minHeight: "70vh" }}
          className={`${styles.container} ${styles.containerFondo}`}
        >
          <Grid
            item
            xs={8}
            sx={{ pt: 0 }}
            className={`${styles.imagen2}`}
          ></Grid>
          <Grid
            className={`${styles.businessContainer} ${styles.containerItem}`}
            item
            xs={8}
            sx={{
              pt: 0,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              color="secondary"
              sx={{
                px: 8,
                pt: 3,
                pb: 2,
                textAlign: "start",
                fontWeight: 600,
              }}
            >
              SGR: La Necesidad
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="secondary"
              sx={{ px: 8, textAlign: "start" }}
            >
              Esta aplicación ha sido desarrollada con MS Dynamics 365 que
              permite personalizar circuitos y procesos para cada cliente e
              incluye los módulos nativos de Marketing, Oportunidades y Atención
              al Cliente por consultas y reclamos.
            </Typography>
            <Typography
              className={`${styles.fondSize}`}
              variant="subtitle2"
              color="secondary"
              sx={{ px: 8, textAlign: "start", pt: 2 }}
            >
              SGR One Click permite implementar una extranet de socios para la
              carga y consulta de información con las funcionalidades que brinda
              el portal de CRM
            </Typography>
          </Grid>
        </Grid>
        <Formulario />
      </Box>
      <Grid
        className={`${styles.productos}`}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          py: 8,
        }}
        container
        spacing={2}
      >
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={fintechSGR}
            className={`${styles.imagenesLogo}`}
            alt="finanzas y Creditos"
          />
        </Grid>
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={trengSGR}
            className={`${styles.imagenesLogo}`}
            alt="Sancor Seguros"
          />
        </Grid>
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={fidus}
            className={`${styles.imagenesLogo}`}
            alt="Origenes"
          />
        </Grid>
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={innovaSGR}
            className={`${styles.imagenesLogo}`}
            alt="Megatlon"
          />
        </Grid>
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={confiablesSGR}
            className={`${styles.imagenesLogo}`}
            alt="Bolsa Comercio"
          />
        </Grid>
        <Grid item className={`${styles.imagenesContainer}`}>
          <img
            src={millsSGR}
            className={`${styles.imagenesLogo}`}
            alt="Bolsa Comercio"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SgrOneClick;
