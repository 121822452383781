import React from "react";
import Carousel from "react-grid-carousel";
import styles from "./MultiCarrusel.module.css";
import finanzasYC from "../image/finanzas-y-creditos.png";
import SancorSeguros from "../image/sancor-seguros.png";
import origenes from "../image/Origenes.png";
import megatlon from "../image/megatlon.png";
import bolsaComercio from "../image/bolsaComercio.png";
import caputo from "../image/caputo.png";
import pampa from "../image/pampa.png";
import bancocomafi from "../image/bancocomafi.png";
import cauciones from "../image/cauciones.png";
import lauraSasia from "../image/laura-sasia.png";
import zf from "../image/zf.png";
import popArgentina from "../image/popArgentina.png";
import arVida from "../image/ArVida.png";
import promover from "../image/Logo Promover Oscuro.png";
import evolucion from "../image/Imag_negro.png"
import fidem from "../image/logo FIDEM png.png";
import cone from "../image/Logo-Cone-Color-e1625429771410.png";
import avaluar from "../image/LOGO AVALUAR CHICO.jpg";
import avalescentro from "../image/logosgr.png";
import avalar from "../image/logoAvalar.png";
import avalganadero from "../image/Logo Negro.png"
import avalfertil from "../image/Logo Aval Fertil.png"
import avalfederal from "../image/Logo-Aval-02.png";
import adp from "../image/logo.png";

function MultiCarrusel() {
  return (
    <>
      <Carousel showDots cols={6} rows={1} gap={5} loop>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={finanzasYC} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={SancorSeguros} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={origenes} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={megatlon} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={bolsaComercio} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="40%" src={fidem} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={caputo} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="70%" src={adp} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
  
            }}
          >
            <img width="100%" src={pampa} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={arVida} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="80%" style={{ marginTop: 23 }} src={cone} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={bancocomafi} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={cauciones} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="70%" src={avalfertil} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="80%" style={{ marginTop: 23 }} src={promover} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={lauraSasia} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={avalar} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={zf} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="80%" style={{ marginTop: 23 }} src={avalescentro} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" src={popArgentina} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" style={{ marginTop: 23 }} src={avalfederal} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="100%" style={{ marginTop: 23 }} src={evolucion} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="40%" style={{ marginLeft: 30 }} src={avaluar} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        <Carousel.Item className={`${styles.imgItem}`}>
          <div
            className={`${styles.aiCard}`}
            style={{
              margin: "0 5px",
              padding: "5px",
            }}
          >
            <img width="55%" src={avalganadero} className={`${styles.img}`} />
          </div>
        </Carousel.Item>
        {/* ... */}
      </Carousel>
    </>
  );
}

export default MultiCarrusel;
